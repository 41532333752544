import React from "react";


function Footer() {
  return (
    <section>
    
      <section className=" text-center">
        <div className="container ">
          <h2 className="text-dark py-3">Find Us Here</h2>
          <div
            className="map-container"
          >
            
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3947.6579447770655!2d77.86534797464972!3d8.336744291699528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b047f641e682fc1%3A0x1139da589a09d0ea!2sBSECURE%20ADVISORY%20SERVICES%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1731493020049!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
            ></iframe>
          </div>
        </div>
     
      </section>
 
       <section id="footer">
   
      <footer
        className="text-center text-white"
        style={{ backgroundColor: "#f1f1f1" }}
      >
        {/* map */}
     
        {/* Grid container */}
        <div className="container pt-4">
          {/* Section: Social media */}
          <section className="mb-4">
            {/* Facebook */}
            <a
              data-mdb-ripple-init=""
              className="btn btn-link btn-floating btn-lg text-dark m-1"
              href="https://www.facebook.com/Bsecure.advisory?mibextid=JRoKGi"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i class="bi bi-facebook"></i>{" "}
            </a>
            {/* Twitter */}
            <a
              data-mdb-ripple-init=""
              className="btn btn-link btn-floating btn-lg text-dark m-1"
              href="#!"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i class="bi bi-twitter-x"></i>{" "}
            </a>

            {/* Instagram */}
            <a
              data-mdb-ripple-init=""
              className="btn btn-link btn-floating btn-lg text-dark m-1"
              href="https://www.instagram.com/bsecure.advisory?igsh=aTN4Z2lvamt5cnA0"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i class="bi bi-instagram"></i>{" "}
            </a>
            {/* Linkedin */}
            <a
              data-mdb-ripple-init=""
              className="btn btn-link btn-floating btn-lg text-dark m-1"
              href="https://www.threads.net/@bsecure.advisory"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i class="bi bi-threads"></i>{" "}
            </a>
            <a
              data-mdb-ripple-init=""
              className="btn btn-link btn-floating btn-lg text-dark m-1"
              href="https://wa.me/message/SIQY7HJY43Q3M1"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i class="bi bi-whatsapp"></i>{" "}
            </a>
            <a
              data-mdb-ripple-init=""
              className="btn btn-link btn-floating btn-lg text-dark m-1"
              href="#!"
              role="button"
              data-mdb-ripple-color="dark"
            >
              <i class="bi bi-youtube"></i>{" "}
            </a>
          </section>
          {/* Section: Social media */}
         
        </div>
        
        {/* Grid container */}
        {/* Copyright */}
        <div
          className="text-center text-dark p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
         
          © 2024 Copyright:
          <a className="text-dark" href="#">
            {" "}
            bsecureadvisiory.co.in
          </a>
        </div>
        {/* Copyright */}
      </footer>
    </section>

    </section>
   
  );
}

export default Footer;
