import React from "react";

function Preloader() {
  return (
    <div className="preloader">
    <div className="cash-container">
      <div className="cash-bill"></div>
      <div className="cash-bill"></div>
      <div className="cash-bill"></div>
      <div className="cash-bill"></div>
      <div className="cash-bill"></div>
    </div>
    <h2>Loading...</h2>
  </div>
  );
}

export default Preloader;
