import React from "react";
import CountUp from "react-countup";
import { useEffect, useState } from "react";

function Achievements() {
  const [startCount, setStartCount] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("Achievements");
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 1.5) {
          setStartCount(true);
          window.removeEventListener("scroll", handleScroll); // Trigger only once
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section id="Achievements">
      <h1 className="py-3 achi-txt display-3">Our Achievements</h1>
      {startCount && (
        <div className="py-5 ">
          <div className="row mt-3 gap-5 justify-content-center">
            <div className="col-12 col-md-3">
              <i class="bi bi-bar-chart-fill"></i>
              <h1 className="display-2 my-5"><CountUp start={0} end={100} duration={2}></CountUp>+</h1>
              <p> Financial Products</p>
            </div>
            <div className="col-12 col-md-3">
              <i className="bi bi-envelope-paper-heart-fill"></i>
              <h1 className="display-2 my-5"><CountUp start={0} end={1000} duration={2}></CountUp>+</h1>
              <p> Happy Clients</p>
            </div>
            <div className="col-12 col-md-3">
              <i className="bi bi-gear-fill"></i>
              <h1 className="display-2 my-5"><CountUp start={0} end={12} duration={2}></CountUp>+</h1>
              <p> Decade of Experience</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Achievements;
