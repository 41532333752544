import React from "react";


const WhatsAppFloat = () => {
  return (
    <a
      href="https://wa.me/message/SIQY7HJY43Q3M1" 
      className="whatsapp-float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // Replace with your preferred icon or use a local image
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppFloat;
