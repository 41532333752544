import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';


const PrivacyPolicyModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Toggle modal visibility
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div>
      {/* Button to trigger the modal */}
      <Button 
       
        className="btn-lg rounded-pill privacy" 
        onClick={handleShow}>
        Data Privacy Policy
      </Button>

      {/* Modal Component */}
      <Modal show={showModal} onHide={handleClose} size="lg" className="privacy-policy-modal">
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title className="text-white">Data Privacy Policy for Intermediaries in Digital Lending</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <h5 className="text-primary">Effective Date:</h5>
          <p><strong>1. Introduction</strong></p>
          <p>
            M/s Bsecure Advisory Pvt Ltd is committed to safeguarding the privacy and security of personal data in our role as intermediaries in the digital lending industry. This Data Privacy Policy outlines how we collect, use, disclose, and protect personal data in compliance with relevant data protection laws.
          </p>
          <p><strong>2. Definitions</strong></p>
          <ul>
            <li><strong>Personal Data:</strong> Any information that can directly or indirectly identify an individual.</li>
            <li><strong>Processing:</strong> Any operation or set of operations performed on personal data, whether by automated means or not, such as collection, recording, organization, storage, adaptation, and retrieval.</li>
            <li><strong>Data Subject:</strong> An individual to whom personal data pertains.</li>
          </ul>
          <p><strong>3. Data Collection</strong></p>
          <ul>
            <li>Identifying and verifying applicants for digital loans.</li>
            <li>Assessing creditworthiness.</li>
            <li>Processing loan applications.</li>
            <li>Servicing and managing loans.</li>
            <li>Complying with legal and regulatory requirements.</li>
          </ul>
          <p>We may collect the following types of personal data, among others:</p>
          <ul>
            <li>Contact information (name, address, email, phone number).</li>
            <li>Financial information (income, expenses, credit history).</li>
            <li>Identification information (government-issued ID, social security number).</li>
            <li>Employment and income details.</li>
            <li>Information related to loan transactions.</li>
          </ul>
          <p><strong>4. Legal Basis for Processing</strong></p>
          <ul>
            <li>To perform the contract with the data subject (e.g., processing loan applications).</li>
            <li>To comply with legal obligations (e.g., regulatory reporting).</li>
            <li>Based on the data subject's consent when required.</li>
            <li>For legitimate interests (e.g., fraud prevention, risk assessment).</li>
          </ul>
          <p><strong>5. Data Security</strong></p>
          <ul>
            <li>Encryption of data in transit and at rest.</li>
            <li>Regular security assessments and audits.</li>
            <li>Access controls and authentication.</li>
            <li>Employee training on data security.</li>
          </ul>
          <p><strong>6. Data Sharing</strong></p>
          <ul>
            <li>Lending institutions and financial partners.</li>
            <li>Credit bureaus and reporting agencies.</li>
            <li>Legal and regulatory authorities.</li>
            <li>Service providers who assist with loan processing.</li>
          </ul>
          <p><strong>7. Data Subject Rights</strong></p>
          <ul>
            <li>Right to access and rectify personal data.</li>
            <li>Right to erasure (where applicable).</li>
            <li>Right to restrict processing.</li>
            <li>Right to data portability.</li>
            <li>Right to object to processing.</li>
            <li>Right not to be subject to automated decision-making.</li>
          </ul>
          <p>To exercise these rights, please contact our Data Protection Officer (DPO) at [DPO contact details].</p>
          <p><strong>8. Data Breach Response</strong></p>
          <p>In the event of a data breach, we will notify affected data subjects and relevant authorities as required by law.</p>
          <p><strong>9. International Data Transfers</strong></p>
          <p>If we transfer personal data outside the jurisdiction, we will ensure that adequate safeguards are in place, such as standard contractual clauses or approved binding corporate rules.</p>
          <p><strong>10. Policy Updates</strong></p>
          <p>We will regularly review and update this policy to stay compliant with data protection regulations. We encourage data subjects to periodically review this policy for any changes.</p>
          <p><strong>11. Contact Information</strong></p>
          <p>If you have any questions or concerns about this policy, please contact our Support Team [customercare@bsecurefin.in]</p>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="secondary" onClick={handleClose} className="rounded-pill">
            Close
          </Button>
          <Button variant="primary" onClick={handleClose} className="rounded-pill">
            Understood
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PrivacyPolicyModal;
