import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import AboutUsNav from "./components/AboutUsNav";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Preloader from "./components/Preloader";

// animations Aos
import AOS from "aos";
import "aos/dist/aos.css";
import Application from "./components/Application";
import WhatsAppFloat from "./components/WhatsAppFloat";

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading by using useEffect
  useEffect(() => {
    // Simulate an API call or resource loading
    const timer = setTimeout(() => {
      setLoading(false); // Hide preloader after resources load
    }, 3000); // Adjust time to simulate load
    AOS.init({
      duration: 1000, // Animation duration (in milliseconds)
      // easing: "ease-in-out", // Easing function
      // once: true, // Whether animation should happen only once
    });
    return () => clearTimeout(timer); // Cleanup timeout if component unmounts
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Preloader /> // Show preloader if still loading
      ) : (
        <div className="main-content">
          {/* Your main content goes here */}
          <Navbar></Navbar>
       
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/about" element={<AboutUsNav />}></Route>
            <Route path="/services" element={<Services />} />
            <Route path="/application" element={<Application />} />

          </Routes>
          <WhatsAppFloat/>
          <Footer></Footer>
        </div>
      )}
    </div>
  );
}

export default App;
