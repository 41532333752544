import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';


const DataStoragePolicyModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Toggle modal visibility
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div>
      {/* Button to trigger the modal */}
      <Button 
    
        className="btn-lg rounded-pill privacy " 
        onClick={handleShow}>
         Data Storage Policy
      </Button>

      {/* Modal Component */}
      <Modal show={showModal} onHide={handleClose} size="lg" className="data-storage-policy-modal">
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title className="text-white">Data Storage Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <h5 className="text-primary">Effective Date:</h5>
          <p><strong>1. Introduction</strong></p>
          <p>
            M/s Bsecure Advisory Pvt Ltd is committed to the responsible and secure storage of personal and financial data. This Data Storage Policy outlines how we store, retain, and protect data in compliance with relevant data protection and industry regulations.
          </p>
          <p><strong>2. Data Types and Categories</strong></p>
          <ul>
            <li><strong>Personal information:</strong> Names, addresses, contact details.</li>
            <li><strong>Financial information:</strong> Income, expenses, credit history.</li>
            <li><strong>Identification information:</strong> Government-issued IDs, social security numbers.</li>
            <li><strong>Employment and income details.</strong></li>
            <li><strong>Loan transaction information.</strong></li>
          </ul>
          <p><strong>3. Data Storage Principles</strong></p>
          <ul>
            <li><strong>a. Legal Compliance:</strong> We adhere to all applicable data protection laws, regulations, and industry standards. We are committed to maintaining the highest standards of data privacy and security.</li>
            <li><strong>b. Data Minimization:</strong> We store only the data that is necessary for our legitimate purposes, such as loan processing and compliance with legal obligations.</li>
            <li><strong>c. Retention Period:</strong> We retain data for the period required by law and regulatory requirements or as necessary for our legitimate business purposes. We will not retain data longer than needed.</li>
            <li><strong>d. Data Security:</strong> We implement robust technical and organizational measures to protect stored data from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, access controls, and regular security assessments.</li>
            <li><strong>e. Regular Review:</strong> We periodically review and assess our data storage practices to ensure compliance with changing regulations and evolving security threats.</li>
          </ul>
          <p><strong>4. Access Control</strong></p>
          <p>
            Access to stored data is restricted to authorized personnel who require it for legitimate business purposes. Access controls include user authentication and authorization mechanisms.
          </p>
          <p><strong>5. Data Backup</strong></p>
          <p>
            We maintain secure and regular data backups to ensure data integrity and availability. Backup procedures include encryption and secure storage.
          </p>
          <p><strong>6. Data Retention and Deletion</strong></p>
          <p>
            We retain data only for the period required by law or as necessary for legitimate business purposes. When data is no longer required, it is securely deleted or anonymized to protect the data subject's privacy.
          </p>
          <p><strong>7. Data Transfer</strong></p>
          <p>
            If data is transferred outside the organization, we ensure appropriate safeguards are in place to protect data privacy and security, including encryption and secure transmission.
          </p>
          <p><strong>8. Policy Review and Updates</strong></p>
          <p>
            This policy will be periodically reviewed and updated to ensure compliance with data protection regulations and industry best practices. Data subjects and stakeholders will be informed of significant policy changes.
          </p>
          <p><strong>9. Contact Information</strong></p>
          <p>If you have any questions or concerns about this policy, please contact our Support Team [customercare@bsecurefin.in]</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="rounded-pill">
            Close
          </Button>
          <Button variant="primary" onClick={handleClose} className="rounded-pill">
            understood
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataStoragePolicyModal;
